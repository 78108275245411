<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-light d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;All Export to Excel
                    </button>

                </span>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <div id="basic-example">
                        <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="onStepChange($event)">

                            <cdk-step [optional]="false">
                                <ng-template cdkStepLabel>
                                    <span class="number">1.</span>
                                    <span>IOT Readings</span>
                                </ng-template>
                                <div class="col-lg-12">
                                    <!-- <form> -->
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="mb-3">
                                                <label class="form-label">Projects Created on</label>
                                                <input type="text" class="form-control date" placeholder="Select Date"
                                                    [(ngModel)]="selectedDateRange"
                                                    (bsValueChange)="onDateChange($event)" (click)="hidden = !hidden"
                                                    name="daterange" autocomplete="off" bsDaterangepicker
                                                    [bsConfig]="datePickerConfig" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 d-flex align-items-center sm-btn">
                                            <button class="btn btn-primary btn-md mt-2"
                                                (click)="filterReadings('filter')">
                                                Apply
                                            </button>
                                            <button class="btn btn-outline-primary btn-md mt-2 ms-2"
                                                (click)="filterReadings('reset')"> Reset
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                @if(cdkStepper.selectedIndex === 0){

                                <section class="">
                                    <ng-template #template let-anchor>
                                        <span>{{ anchor.nativeElement.innerText }}</span>
                                    </ng-template>
                                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                        (mouseover)="showTooltip($event)">
                                        <kendo-grid [data]="readingsgridData" [pageSize]="reading_pageSize"
                                            [skip]="skip" [sortable]="true" [groupable]="false"
                                            [selectable]="selectableSettings" [pageable]="{
                                                            buttonCount: buttonCount,
                                                            info: info,
                                                            type: type,
                                                            pageSizes: pageSizes,
                                                            previousNext: previousNext
                                                          }" [reorderable]="true" filterable="menu"
                                            [filter]="readingsstate.filter" [sort]="readingsstate.sort"
                                            [columnMenu]="{ filter: true }" [resizable]="true" kendoGridSelectBy="id"
                                            scrollable="scrollable" (filterChange)="onFilterChange($event)"
                                            (sortChange)="onSortChange($event)" (pageChange)="devicePageChange($event)"
                                            (selectionChange)="selectionChanged($event)"
                                            (dataStateChange)="dataStateChange($event)" style="width: 100%;"
                                            scrollable="scrollable">


                                            <ng-template kendoGridToolbarTemplate>
                                                <!-- <kendo-textbox [style.width.px]="250"
                                                    placeholder="Search in all columns..."
                                                    (valueChange)="readingsFilter($event)"></kendo-textbox>
                                                <kendo-grid-spacer></kendo-grid-spacer> -->
                                                <div >
                                                    <kendo-textbox [style.width.px]="250"
                                                        [(ngModel)]="readingsSearchValue" [clearButton]="true"
                                                        (valueChange)="clearSearchValueChangeIotReadings($event)"
                                                        placeholder="Search in all columns...">
                                                    </kendo-textbox>

                                                    <button class="btn btn-primary btn-md border-left-none"
                                                        (click)="readingsFilter($event)">
                                                        <i class="fa fa-search"></i>
                                                    </button>
                                                </div>
                                                <kendo-grid-spacer></kendo-grid-spacer>
                                                <!-- <button kendoGridPDFCommand class="btn btn-light">
                                                    <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->
                                                <button kendoGridExcelCommand class="btn btn-light"
                                                    (click)="onReadingsExport('Excel')">
                                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                                </button>

                                            </ng-template>

                                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                [width]="40" class="no-tooltip"></kendo-grid-checkbox-column>
                                            <kendo-grid-column [width]="100" field="utilityType"
                                                title="Utility Type"></kendo-grid-column>
                                            <kendo-grid-column [width]="100" field="deviceSRNO"
                                                title="Device Sr. No"></kendo-grid-column>
                                                <kendo-grid-column [width]="100" field="macAddress"
                                                title="Mac Address"></kendo-grid-column>
                                            <kendo-grid-column [width]="100" [hidden]="true" field="isMonitoringDevice"
                                                title="Monitoring Device"></kendo-grid-column>
                                            <kendo-grid-column [width]="100" field="date_Time" title="Date & time" filter="date"
                                                filter="date">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    {{ dataItem.date_Time | date: 'dd MMM yyyy hh:mm a' }}
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column field="meterReading" filter="numeric"
                                                title="Meter reading" [width]="100"></kendo-grid-column>
                                                <kendo-grid-column field="meterReadingEB" filter="numeric"
                                                title="meter Reading EB" [width]="100"></kendo-grid-column>
                                                <kendo-grid-column field="meterReadingDG" filter="numeric"
                                                title="meter Reading DG" [width]="100"></kendo-grid-column>
                                            <kendo-grid-column field="onOffStatus" [hidden]="true" title="On / off status"
                                                [width]="80"></kendo-grid-column>
                                            <!-- <kendo-grid-column field="convertedJson"
                                            title="Converted Json"></kendo-grid-column> -->
                                            <kendo-grid-column field="projectName" title="Project name"
                                                [width]="100"></kendo-grid-column>
                                                <kendo-grid-command-column [columnMenu]="false" [width]="50">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                      <button class="btn btn-primary btn-sm m-lg-auto"
                                                              kendoTooltip
                                                              title="View Iot Reading Details"
                                                              (click)="navigateToViewReadings(dataItem, 'Reading Details')">
                                                        <i class="fas fa-info-circle"></i>
                                                      </button>
                                                    </ng-template>
                                                  </kendo-grid-command-column>
                                                  

                                            
                                        </kendo-grid>
                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="mySelectionReadings.length>0 ? mySelectionReadings:readingsgridData.data"
                                                #exportiotreadings>
                                                <kendo-grid-column [width]="100" field="utilityType"
                                                    title="Utility Type"></kendo-grid-column>
                                                <kendo-grid-column [width]="100" field="deviceSRNO"
                                                    title="Device Sr. No"></kendo-grid-column>
                                                    <kendo-grid-column [width]="100" field="macAddress"
                                                    title="Mac Address"></kendo-grid-column>
                                                    <kendo-grid-column [width]="100" [hidden]="true" field="isMonitoringDevice"
                                                    title="Monitoring Device"></kendo-grid-column>
                                                <kendo-grid-column [width]="100" field="date_Time" title="Date & time"
                                                    filter="date">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.date_Time | date: 'dd MMM yyyy hh:mm a' }}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field="meterReading" title="Meter reading"
                                                    [width]="100"></kendo-grid-column>
                                                <kendo-grid-column field="onOffStatus" title="On / off status"
                                                    [width]="80"></kendo-grid-column>
                                                <kendo-grid-column field="projectName" title="project name"
                                                    [width]="100"></kendo-grid-column>
                                                <kendo-grid-excel fileName="IotReadings.xlsx"></kendo-grid-excel>
                                            </kendo-grid>

                                        </div>
                                    </div>

                                </section>


                                }
                            </cdk-step>

                            <cdk-step [optional]="true">
                                <ng-template cdkStepLabel>
                                    <span class="number">2.</span>
                                    <span>Archived Readings</span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 1){
                                <section>
                                    <ng-template #template let-anchor>
                                        <span>{{ anchor.nativeElement.innerText }}</span>
                                    </ng-template>
                                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                        (mouseover)="showTooltip($event)">
                                        <kendo-grid [data]="archivedgridData" [pageSize]="archived_pageSize"
                                            [skip]="archivedSkip" [sortable]="true" [groupable]="false"
                                            [selectable]="selectableSettings" [pageable]="{
                                                                                            buttonCount: buttonCount,
                                                                                            info: info,
                                                                                            type: type,
                                                                                            pageSizes: pageSizes,
                                                                                            previousNext: previousNext
                                                                                          }" [reorderable]="true"
                                            filterable="menu" [filter]="archivedstate.filter"
                                            [sort]="archivedstate.sort" [columnMenu]="{ filter: true }"
                                            [resizable]="true" kendoGridSelectBy="id" scrollable="scrollable"
                                            (filterChange)="onArchivedFilterChange($event)"
                                            (sortChange)="onArchivedSortChange($event)"
                                            (pageChange)="archivedDevicePageChange($event)"
                                            (selectionChange)="archivedselectionChanged($event)"
                                            (dataStateChange)="archivedDataStateChange($event)">


                                            <ng-template kendoGridToolbarTemplate>
                                                <!-- <kendo-textbox [style.width.px]="250"
                                                    placeholder="Search in all columns..."
                                                    (valueChange)="archivedFilter($event)"></kendo-textbox> -->

                                                <div >
                                                    <kendo-textbox [style.width.px]="250"
                                                        [(ngModel)]="archivedSearchValue" [clearButton]="true"
                                                        (valueChange)="clearSearchValueChangeArchiveReadings($event)"
                                                        placeholder="Search in all columns...">
                                                    </kendo-textbox>

                                                    <button class="btn btn-primary btn-md border-left-none"                                                       
                                                        (click)="archivedFilter($event)">
                                                        <i class="fa fa-search"></i>
                                                    </button>
                                                </div>
                                                <kendo-grid-spacer></kendo-grid-spacer>
                                                <!-- <button [routerLink]="'/payloadConfiguration'" class="btn btn-light" kendoGridAddCommand > 
                                    <span class="fas fa-file-alt"> </span> &nbsp; Add Payload Configurations </button> -->
                                                <!-- <button kendoGridPDFCommand class="btn btn-light">
                                                    <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->
                                                <button kendoGridExcelCommand class="btn btn-light"
                                                    (click)="onArchivedExport('Excel')">
                                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                                </button>

                                            </ng-template>

                                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                [width]="40" class="no-tooltip"></kendo-grid-checkbox-column>
                                            <kendo-grid-column field="deviceSRNO" title="Device Sr. No"></kendo-grid-column>
                                            <kendo-grid-column  field="deviceAddress"
                                            title="Mac Address"></kendo-grid-column>
                                            <kendo-grid-column [width]="190" field="receivedTime" filter="date"
                                                title="Date & time">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    {{ dataItem.receivedTime | date: 'dd MMM yyyy hh:mm a' }}
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                            
                                            <kendo-grid-column field="comment" title="comment">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    {{ dataItem.comment || '-' }}
                                                </ng-template>
                                            </kendo-grid-column>
                                            
                                            <kendo-grid-command-column  [columnMenu]="false" 
                                                [width]="100">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <button class="btn btn-primary btn-sm m-lg-auto "   kendoTooltip  title="View Archived Reading Details"
                                                        (click)="navigateToViewReadings(dataItem,'Archived Details')">
                                                        <i class="fas fa-info-circle"></i>
                                                    </button>
                                                </ng-template>
                                            </kendo-grid-command-column>
                                        </kendo-grid>

                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="myArchivedSelectionReadings.length>0 ? myArchivedSelectionReadings:archivedgridData.data"
                                                #exportArchived>
                                                <kendo-grid-column field="deviceSRNO"
                                                    title="Device Sr. No"></kendo-grid-column>
                                                    <kendo-grid-column [width]="100" field="deviceAddress"
                                                    title="Mac Address"></kendo-grid-column>
                                                <kendo-grid-column [width]="190" field="dateTime" filter="date" title="Date & time">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.receivedTime | date: 'dd MMM yyyy hh:mm a' }}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                                <kendo-grid-column field="comment" title="comment"></kendo-grid-column>
                                                
                                                <kendo-grid-command-column  [columnMenu]="false"
                                                    [width]="100">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <button class="btn btn-primary btn-sm m-lg-auto "
                                                            (click)="navigateToViewReadings(dataItem,'Archived Details')">
                                                            <i class="fas fa-info-circle"></i>
                                                        </button>
                                                    </ng-template>
                                                </kendo-grid-command-column>
                                                <kendo-grid-excel fileName="ArchivedReadings.xlsx"></kendo-grid-excel>
                                            </kendo-grid>

                                        </div>
                                    </div>
                                </section>
                                }
                            </cdk-step>

                            <cdk-step [optional]="true">
                                <ng-template cdkStepLabel>
                                    <span class="number">3.</span>
                                    <span>RAW Data</span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 2){
                                <section>

                                    <ng-template #template let-anchor>
                                        <span>{{ anchor.nativeElement.innerText }}</span>
                                    </ng-template>
                                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                        (mouseover)="showTooltip($event)">
                                        <kendo-grid [data]="rawDatagridData" [pageSize]="rawData_pageSize"
                                            [skip]="rawSkip" [sortable]="true" [groupable]="false"
                                            [selectable]="selectableSettings"
                                            [pageable]="{
                                                                                                                            buttonCount: buttonCount,
                                                                                                                            info: info,
                                                                                                                            type: type,
                                                                                                                            pageSizes: pageSizes,
                                                                                                                            previousNext: previousNext
                                                                                                                          }" [reorderable]="true"
                                            filterable="menu" [filter]="rawDatastate.filter" [sort]="rawDatastate.sort"
                                            [columnMenu]="{ filter: true }" [resizable]="true" kendoGridSelectBy="id"
                                            scrollable="scrollable" (filterChange)="onRawDataFilterChange($event)"
                                            (sortChange)="onRawDataSortChange($event)"
                                            (pageChange)="rawDataPageChange($event)"
                                            (selectionChange)="rawDataSelectionChanged($event)"
                                            (dataStateChange)="rawDataStateChange($event)">


                                            <ng-template kendoGridToolbarTemplate>
                                               
                
                                                <div>
                                                    <kendo-textbox [style.width.px]="250" [(ngModel)]="rawDataSearchValue" [clearButton]="true"
                                                    (valueChange)="clearSearchValueChangeRawData($event)"
                                                    placeholder="Search in all columns...">
                                                </kendo-textbox>
                          
                                                    <button class="btn btn-primary btn-md border-left-none"  (click)="rawFilter($event)">
                                                      <i class="fa fa-search"></i>
                                                    </button>
                                                  </div>
                                                <kendo-grid-spacer></kendo-grid-spacer>
                                               
                                                <button kendoGridExcelCommand class="btn btn-light" (click)="onRawExport('Excel')">
                                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                                </button>
                
                                            </ng-template>




                                           

                                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                [width]="40" class="no-tooltip"></kendo-grid-checkbox-column>
                                            <kendo-grid-column  field="deviceSRNO" title="Device Sr. No"></kendo-grid-column>
                                            <kendo-grid-column  field="deviceAddress"
                                            title="Mac Address"></kendo-grid-column>
                                            <kendo-grid-column  field="receivedTime" filter="date" title="Date & time">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    {{ dataItem.receivedTime | date: 'dd MMM yyyy hh:mm a' }}
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                            <kendo-grid-command-column  [columnMenu]="false"  title="View Details"    [width]="100" 
                                                >
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <button class="btn btn-primary btn-sm m-lg-auto "  kendoTooltip  title="View Raw  Details"
                                                        (click)="navigateToViewReadings(dataItem,'RAW Data Details')">
                                                        <i class="fas fa-info-circle"></i>
                                                    </button>
                                                </ng-template>
                                            </kendo-grid-command-column>

                                            
                                        </kendo-grid>

                                        <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                            <kendo-grid
                                                [kendoGridBinding]="myrawDataSelectionReadings.length>0 ? myrawDataSelectionReadings:rawDatagridData.data"
                                                #hiddenRawGrid>
                                                <kendo-grid-column field="deviceSRNO"
                                                    title="Device Sr. No"></kendo-grid-column>
                                                    <kendo-grid-column [width]="100" field="deviceAddress"
                                                    title="Mac Address"></kendo-grid-column>
                                                <kendo-grid-column [width]="190" field="dateTime" filter="date" title="Date & time">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        {{ dataItem.receivedTime | date: 'dd MMM yyyy hh:mm a' }}
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                                <kendo-grid-command-column  [columnMenu]="false"
                                                    [width]="100">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <button class="btn btn-primary btn-sm m-lg-auto "
                                                            (click)="navigateToViewReadings(dataItem,'RAW Data Details')">
                                                            <i class="fas fa-info-circle"></i>
                                                        </button>
                                                    </ng-template>
                                                </kendo-grid-command-column>
                                                <kendo-grid-excel fileName="RawData.xlsx"></kendo-grid-excel>
                                            </kendo-grid>

                                        </div>
                                    </div>
                                </section>
                                }
                            </cdk-step>

                        </ng-stepper>
                    </div>



                </div>
            </div>
        </div>
    </div>

</div>