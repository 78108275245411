<div class="container-fluid">
    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-0 font-size-18">Add IOT Devices</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item active" [routerLink]="'/iotDevices'"><a
                                href="javascript: void(0);">IOT Devices</a>
                        </li>

                        <li class="breadcrumb-item">Add IOT Devices
                        </li>

                    </ol>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-lg-12">


            <div class="card">
                <div class="card-body">

                    <accordion [closeOthers]="false" class="accordion custom-accordian" [isAnimated]="true">
                        <accordion-group [isOpen]="isAllOpen" heading="Add IOT Devices"
                            class="accordion-item mb-3 panel-open" #item1>
                            <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                                <i class="mdi font-size-24"
                                    [ngClass]="!item1?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                            </span>

                            <div class="row">
                                <div class="col-lg-12">
                                    <form [formGroup]="deviceForm">
                                        <div class="row">
                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Device SR No <span
                                                            class="required">*</span></label>
                                                    <input type="text" formControlName="deviceSRNo"
                                                        placeholder="Enter Device SR No" class="form-control">
                                                    @if(submitted && deviceForm.controls['deviceSRNo'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['deviceSRNo'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.deviceSRNo.required}}</div>}
                                                    </div>}
                                                </div>
                                            </div>

                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">MAC Address <span
                                                            class="required">*</span></label>
                                                    <input type="text" formControlName="macAddress"
                                                        placeholder="Enter MAC Address" class="form-control">
                                                    @if(submitted && deviceForm.controls['macAddress'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['macAddress'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.macAddress.required}}</div>}
                                                    </div>}

                                                </div>
                                            </div>

                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> Utility<span
                                                            class="required">*</span></label>
                                                    <ng-select
                                                        [items]="configData?.utilities" bindLabel="name" bindValue="id"
                                                        formControlName="masterId" placeholder="Select"
                                                        [multiple]="false"></ng-select>
                                                    @if(submitted && deviceForm.controls['masterId'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['masterId'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.masterId.required}}</div>}
                                                    </div>}

                                                </div>
                                            </div>

                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Supplier Name<span
                                                            class="required">*</span> </label>
                                                    <!-- <ng-select [items]="supplierData" bindLabel="name" bindValue="id"
                                                        formControlName="supplierId" placeholder="Select"
                                                        [multiple]="false"></ng-select> -->
                                                        <ng-select [items]="configData?.gatewayProviders" bindLabel="name" bindValue="id"
                                                        formControlName="supplierId" placeholder="Select"
                                                        [multiple]="false"></ng-select>
                                                    @if(submitted && deviceForm.controls['supplierId'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['supplierId'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.supplierId.required}}</div>}
                                                    </div>}

                                                </div>
                                            </div>

                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Project Name <span
                                                            class="required">*</span></label>
                                                    <ng-select [items]="configData?.projects" bindLabel="name"
                                                        bindValue="id" formControlName="projectId" placeholder="Select"
                                                        [multiple]="false"></ng-select>

                                                    @if(submitted && deviceForm.controls['projectId'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['projectId'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.projectId.required}}</div>}
                                                    </div>}

                                                </div>
                                            </div>


                                            <div class="col-lg-4  mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label">Year Of Make<span
                                                            class="required">*</span></label>
                                                    <kendo-datepicker formControlName="yearOfMake" format="yyyy"
                                                        [bottomView]="'decade'" [topView]="'century'"
                                                        (valueChange)="onYearChange($event)">
                                                    </kendo-datepicker>
                                                    @if(submitted && deviceForm.controls['yearOfMake'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['yearOfMake'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.yearOfMake.required}}</div>}
                                                    </div>}
                                                </div>
                                            </div>


                                            <div class="col-lg-4 mb-3">
                                                <div class="mb-3">
                                                    <label class="form-label"> Response Configuration<span
                                                            class="required">*</span> </label>
                                                    <ng-select [items]="configData?.responseConfigurations"
                                                        bindLabel="responseName" bindValue="id"
                                                        formControlName="responseConfigurationId" placeholder="Select"
                                                        [multiple]="false"></ng-select>
                                                    @if(submitted &&
                                                    deviceForm.controls['responseConfigurationId'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['responseConfigurationId'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.responseConfigurationId.required}}
                                                        </div>}
                                                    </div>}

                                                </div>
                                            </div>

                                            <div class="col-lg-4 mb-3">
                                                <div class="mb-3">
                                                    <label for="b">Monitoring Device<span
                                                            class="required">*</span></label>&nbsp;&nbsp;
                                                    <ui-switch [ngClass]="{
                                                    'switch-checked': deviceForm.controls['isMonitoringDevice'].value,
                                                    'switch-unchecked': !deviceForm.controls['isMonitoringDevice'].value
                                                  }" formControlName="isMonitoringDevice" [uncheckedLabel]="'Off'"
                                                        [checkedLabel]="'On'" size="small" class="me-1">
                                                    </ui-switch>


                                                    @if(submitted &&
                                                    deviceForm.controls['isMonitoringDevice'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['isMonitoringDevice'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.isMonitoringDevice.required}}
                                                        </div>}
                                                    </div>}
                                                    <!-- <ui-switch color="#008000" formControlName="status" uncheckedLabel="Inactive" checkedLabel="Active"
                                                                    size="small" class="me-1"></ui-switch> -->
                                                </div>
                                            </div>

                                            <div class="col-lg-4 mb-3">
                                                <div class="mb-3">
                                                    <label for="b">Is Wired Communication Device<span
                                                            class="required">*</span></label>&nbsp;&nbsp;
                                                    <ui-switch [ngClass]="{
                                                    'switch-checked': deviceForm.controls['isWiredCommunicationDevice'].value,
                                                    'switch-unchecked': !deviceForm.controls['isWiredCommunicationDevice'].value
                                                  }" formControlName="isWiredCommunicationDevice" [uncheckedLabel]="'No'"
                                                        [checkedLabel]="'Yes'" size="small" class="me-1" (change)="onSwitchChange($event)">
                                                    </ui-switch>
                                                </div>
                                            </div>


                                            <div class="col-lg-4  mb-3" *ngIf="deviceForm.value.isWiredCommunicationDevice">
                                                <div class="mb-3">
                                                    <label class="form-label">GateWay <span
                                                            class="required">*</span></label>
                                                    <ng-select [items]="configData?.gatewayInformation" bindLabel="gatewayAddress"
                                                        bindValue="id" formControlName="gatewayInformationId" placeholder="Select"
                                                        [multiple]="false"></ng-select>

                                                    @if(submitted && deviceForm.controls['gatewayInformationId'].errors) {
                                                    <div class="invalid-feedback">
                                                        @if(deviceForm.controls['gatewayInformationId'].errors['required'])
                                                        {
                                                        <div>{{deviceValidations.gatewayInformationId.required}}</div>}
                                                    </div>}

                                                </div>
                                            </div>



                                            <ul class="list-inline wizard d-flex justify-content-end mb-0 mt-4">


                                                <li (click)="saveDevice(deviceDetails?'Update':'Save')"
                                                    class="next list-inline-item" aria-disabled="false">
                                                    <button class="btn btn-primary"
                                                        cdkStepperNext>{{deviceDetails?'Update':'Save'}}</button>
                                                </li>


                                            </ul>

                                        </div>

                                    </form>
                                </div>
                            </div>

                        </accordion-group>


                    </accordion>



                </div>
                <!-- end card body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>

</div>