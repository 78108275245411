import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrudService } from '../../core/services/crud.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { map, catchError, exhaustMap, finalize } from 'rxjs/operators';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { Validations } from '../../shared/constants/validations';
import { LoaderService } from '../../core/services/loader.service';
import {
  getGatewayList,
  getGatewayListSucess,
  getGatewayListFailure,
  createGateway,
  creategatewaySuccess,
  creategatewayFailure,
  updateGateWay,
  updateGateWayFailure,
  updateGateWaySuccess,
  deleteGateWay,
  deleteGateWaySuccess,
  deleteGateWayFailure,
  SetGatewayStatus,
  getAllGatewayListFailure,
  getAllGatewayListSucess,
  getAllGatewayList,
  getGatewayExport,
  getGatewayExportSucess,
  getGatewayExportFailure,
} from './gateway.action';
import { GatewayService } from '../../core/services/gateway.service';

@Injectable()
export class gatewayEffect {
  ServiceError = Validations.ServiceError;

  createGateway$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createGateway),
      exhaustMap((gatewayData) => {
        console.log(gatewayData);
        this.loaderService.setLoading(true);
        return this.gatewayService.createGateway(gatewayData.gateway).pipe(
          map((response: any) => {
            console.log(response);
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 201) {
              this.sharedService.showMessageDialog(
                'Success',
                'Gatway added successfully',
                SweetAlertIcon.SUCCESS
              );
              // this.router.navigate(['/users']);
              return creategatewaySuccess({ creategateway_Response: response });
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return creategatewayFailure({ createGatewayError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(creategatewayFailure({ createGatewayError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getGatewayList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGatewayList),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.gatewayService
          .getgateway(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getGatewayListSucess({
                  getGateway_Response: response.data,
                });
              } else {
                return getGatewayListFailure({ getGatewayError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getGatewayListFailure({ getGatewayError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getGatewayExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGatewayExport),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.gatewayService
          .getgateway(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getGatewayExportSucess({
                  getGatewayExportResponse: response.data,
                });
              } else {
                return getGatewayExportFailure({
                  getGatewayExportError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getGatewayExportFailure({ getGatewayExportError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllGatewayList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllGatewayList),
      exhaustMap(({ pageIndex, pageSize, searchTerm, id, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.gatewayService
          .getgateway(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllGatewayListSucess({
                  getAllGateway_Response: response.data,
                });
              } else {
                return getAllGatewayListFailure({
                  getAllGatewayError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getAllGatewayListFailure({ getAllGatewayError: error })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  updateGateWay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGateWay),
      exhaustMap((updatedData) => {
        console.log(updatedData, '-----');
        this.loaderService.setLoading(true);
        return this.gatewayService.updateGateway(updatedData.updatedData).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
               ' Gatway Updated successfully',
                SweetAlertIcon.SUCCESS
              );
              return updateGateWaySuccess({ updateGateWayResponse: response });
              //this.router.navigate(['/users']);
              // return getuserlist({ pageIndex: 1, pageSize: 10, searchTerm: '', criteria: "" })
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return updateGateWayFailure({ updateGateWayError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(updateGateWayFailure({ updateGateWayError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  deleteGateWay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteGateWay),
      exhaustMap((userData, id) => {
        console.log(userData, '-----', id);
        this.loaderService.setLoading(true);
        return this.gatewayService.deleteMaster(userData.id).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteGateWaySuccess({ deleteGateWayResponse: response });
              // return getuserlist({ pageIndex: 1, pageSize: 10, searchTerm: '' })
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteGateWayFailure({ deleteGateWayError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteGateWayFailure({ deleteGateWayError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  SetGatewayStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetGatewayStatus),
      exhaustMap((request) => {
        console.log(request);
        this.loaderService.setLoading(true);
        return this.gatewayService.gateWayOnOff(request.request).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            if (response && response.statusCode === 200) {
              this.sharedService.showMessageDialog(
                'Success',
                response.message,
                SweetAlertIcon.SUCCESS
              );
              return deleteGateWaySuccess({ deleteGateWayResponse: response });
              // return getuserlist({ pageIndex: 1, pageSize: 10, searchTerm: '' })
            } else {
              this.sharedService.showMessageDialog(
                'Failure',
                response.message,
                SweetAlertIcon.ERROR
              );
              return deleteGateWayFailure({ deleteGateWayError: response });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(deleteGateWayFailure({ deleteGateWayError: error }));
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private CrudService: CrudService,
    private gatewayService: GatewayService,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) {}
}
