import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { createGateway, updateGateWay } from '../../../store/GateWay/gateway.action';
import { Validations } from '../../../shared/constants/validations';
Validations
@Component({
  selector: 'app-rp-create-gateway',
  templateUrl: './rp-create-gateway.component.html',
  styleUrl: './rp-create-gateway.component.scss'
})
export class RpCreateGatewayComponent {
  fileValidations = Validations.gatewayPage;
  creategatewayForm!: UntypedFormGroup;
  currentUserData: any;
  submitted: boolean = false;
  selectedGateway: any;
  @Input() projectId: any;
  @Input() gatewayUpdateData: any;
  configData: any;
  gatewayProvidersList: any[] = []
  regex = /^[a-zA-Z0-9]+$/;
  listofProjects: any = [];
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.creategatewayForm = this.formBuilder.group({
      gatewayAddress: ['', [Validators.required, Validators.maxLength(200)]], // Optional
      projectId: [null, [Validators.required]], // Mandatory if project selection is critical
      shaft: ['', []], // Optional
      blockName: ['', []], // Optional
      floorNum: ['',[]], // Optional, only numbers allowed
      networkStatus: ['', []], // Optional
    });



  }

  ngOnInit(): void {

    this.getAllConfigData();

  }
  get gatewayControls() {
    return this.creategatewayForm.controls;
  }


  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (data.statusCode == 200 && data.message == 'Config data retrieved successfully.') {
        this.listofProjects = data.data.projects;
        console.log('listofProjects data retrieved successfully', this.listofProjects)
      }

      this.creategatewayForm.patchValue({
        gatewayAddress: this.gatewayUpdateData?.gatewayAddress ? this.gatewayUpdateData.gatewayAddress : '',
        projectId: this.gatewayUpdateData?.projectId ? this.gatewayUpdateData.projectId : '',
        shaft: this.gatewayUpdateData?.shaft || '', // Optional, assuming `shaft` is a property of `gatewayUpdateData`
        blockName: this.gatewayUpdateData?.blockName || '', // Optional
        floorNum: this.gatewayUpdateData?.floorNum || '', // Optional, only numbers allowed
        networkStatus: this.gatewayUpdateData?.networkStatus || '' // Optional
      });
      
    });
  }

  valueChange(value: any): void {
    console.log("valueChange", value);
    this.selectedGateway = value;
  }

  getProjectNameById(id: string): string | undefined {
    const project = this.listofProjects.find((project:any) => project.id === id);
    return project ? project.name : undefined;  // Returns the name or undefined if not found
  }


  findInvalidControls(form: any): { [key: string]: any }[] {
    const invalidControls = [];
    const controls = form.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        invalidControls.push({
          controlName: name,
          controlErrors: controls[name].errors,
        });
      }
    }

    return invalidControls;
  }


  saveGateway(type: string) {
    this.submitted = true;
    const invalidControls = this.findInvalidControls(this.creategatewayForm);
    console.log('Invalid controls:', invalidControls);
    console.log(this.creategatewayForm.value);
    if (this.creategatewayForm.valid) {
      this.loaderService.setLoading(true);
      let payload = {
        gatewayAddress: this.creategatewayForm.get('gatewayAddress')?.value,
        projectName:  this.getProjectNameById(this.creategatewayForm.get('projectId')?.value),
        projectId: this.creategatewayForm.get('projectId')?.value,
        shaft: this.creategatewayForm.get('shaft')?.value,
        blockName: this.creategatewayForm.get('blockName')?.value,
        floorNum: this.creategatewayForm.get('floorNum')?.value,
        networkStatus: this.creategatewayForm.get('networkStatus')?.value
      };
      
      console.log(payload); // To check the created payload
      
      console.log(payload);
      console.log(this.creategatewayForm.value);
      if (type == 'Create') {
        this.store.dispatch(createGateway({ gateway: payload })
        );
      } else if (type == 'Update') {
        let payload = {
          gatewayAddress: this.creategatewayForm.get('gatewayAddress')?.value,
          projectName:  this.getProjectNameById(this.creategatewayForm.get('projectId')?.value),
          projectId: this.creategatewayForm.get('projectId')?.value,
          shaft: this.creategatewayForm.get('shaft')?.value,
          blockName: this.creategatewayForm.get('blockName')?.value,
          floorNum: this.creategatewayForm.get('floorNum')?.value,
          networkStatus: this.creategatewayForm.get('networkStatus')?.value,
          id: this.gatewayUpdateData.id
        }
        console.log(this.creategatewayForm.value);
        this.store.dispatch(updateGateWay({ updatedData: payload })
        );
      }
      this.modalService.hide();
    }
  }

  dismissModal(): void {
    this.modalService.hide();
  }
}
