import { Helper } from '../../../core/helpers/helper';
import { ProjectData } from '../../../shared/modals/projectModal';
import { SharedService } from '../../../shared/shared.service';
import { LoaderService } from '../../../core/services/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Location } from '@angular/common';
import { ReadingsService } from '../../../core/services/readings.services';
import { SweetAlertIcon } from '../../../shared/constants/enum';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'], // Corrected: styleUrl to styleUrls
})
export class ViewDetailsComponent implements OnInit {
  breadCrumbItems: Array<{}> | undefined;
  projectDetails = {
    name: 'Sample Project',
    projectType: { name: 'Residential' },
    region: { name: 'North Region' },
    address: '123 Main Street, City, Country',
    societyHead: {
      name: 'John Doe',
      merchantId: 'M12345',
      accountName: 'John Doe Account',
      accountNumber: '1234567890',
      bankName: 'Sample Bank',
      branchName: 'Main Branch',
      ifscCode: 'IFSC1234',
    },
  };
  type: string = '';
  viewDetails: any;

  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private readingsService: ReadingsService,
  ) {
    this.type = history.state.type;
    this.viewDetails = JSON.parse(history.state.readings);
    if (this.viewDetails?.convertedJson) {
      this.viewDetails.convertedJson = JSON.parse(
        this.viewDetails.convertedJson
      );
    }
    if (this.viewDetails?.rawJson) {
      this.viewDetails.rawJson = JSON.parse(this.viewDetails.rawJson);
    }
    if (this.viewDetails?.rawData) {
      this.viewDetails.convertedJson = JSON.parse(this.viewDetails.rawData);
      this.viewDetails.date_Time = this.viewDetails.dateTime;
      this.viewDetails.devicesSRNO = this.viewDetails.deviceAddress;
      this.viewDetails.utilityType = this.viewDetails.utility;
    }

    switch (this.type) {
      case 'Reading Details':
        this.fetchiotData(this.viewDetails?.id);
        // Add any logic here if needed for 'Reading Details'
        break;

      case 'Archived Details':
        if (this.viewDetails?.rawData) {
          this.viewDetails.convertedJson = JSON.parse(this.viewDetails.rawData);
          this.viewDetails.date_Time = this.viewDetails.receivedTime;
          this.viewDetails.macAddress = this.viewDetails.deviceAddress;
          this.viewDetails.utilityType = this.viewDetails.utility;
        }

        this.fetchArchiveData(this.viewDetails?.id);

        break;

      case 'RAW Data Details':
        this.fetchRawData(this.viewDetails?.id);
        // Add any logic here if needed for 'RAW Data Details'
        break;

      default:
        // Optional: handle other unexpected cases or do nothing
        break;
    }


    this.breadCrumbItems = [
      { label: 'Iot Readings', url: 'iotReading', active: true },
      {
        label:
          this.type === 'Reading Details'
            ? 'Iot Reading Details'
            : this.type === 'Archived Details'
              ? 'Archived Details'
              : this.type === 'RAW Data Details'
                ? 'RAW Data Details'
                : '',
      },
    ];
  }

  fetchiotData(id: string): void {
    this.loaderService.setLoading(true)
    this.readingsService.getIotData(id).subscribe({
      next: (data) => {
        this.loaderService.setLoading(false)
        console.log('Raw Data:', data);
        if (data && data.statusCode == 200) {

          this.viewDetails = data.data; // Store or use the data as needed
          if (this.viewDetails?.convertedJson) {
            this.viewDetails.convertedJson = JSON.parse(
              this.viewDetails.convertedJson
            );
          }
          if (this.viewDetails?.rawJson) {
            this.viewDetails.rawJson = JSON.parse(this.viewDetails.rawJson);
          }
          if (this.viewDetails?.rawData) {
            this.viewDetails.convertedJson = JSON.parse(this.viewDetails.rawData);
            this.viewDetails.date_Time = this.viewDetails.dateTime;
            this.viewDetails.devicesSRNO = this.viewDetails.deviceAddress;
            this.viewDetails.utilityType = this.viewDetails.utility;
          }
        } else {
          this.sharedService.showMessageDialog(
            data.data.message, '',
            SweetAlertIcon.ERROR
          );
        }
        console.log(this.viewDetails);

      },
      error: (error) => {
        console.error('Error fetching raw data:', error);
      }
    });
  }

  fetchArchiveData(id: string): void {
    this.loaderService.setLoading(true)
    this.readingsService.getArchiveData(id).subscribe({
      next: (data) => {
        this.loaderService.setLoading(false)
        if (data && data.statusCode == 200) {

          this.viewDetails = data.data; // Store or use the data as needed
          if (this.viewDetails?.convertedJson) {
            this.viewDetails.convertedJson = JSON.parse(
              this.viewDetails.convertedJson
            );
          }
          if (this.viewDetails?.rawJson) {
            this.viewDetails.rawJson = JSON.parse(this.viewDetails.rawJson);
            this.viewDetails.date_Time = this.viewDetails.receivedTime;
            this.viewDetails.macAddress = this.viewDetails.deviceAddress;
            this.viewDetails.utilityType = this.viewDetails.utility;
          }
       
        } else {
          this.sharedService.showMessageDialog(
            data.data.message, '',
            SweetAlertIcon.ERROR
          );
        }

      },
      error: (error) => {
        console.error('Error fetching raw data:', error);
      }
    });
  }




  fetchRawData(id: string): void {
    this.loaderService.setLoading(true)
    this.readingsService.getRawData(id).subscribe({
      next: (data) => {
        this.loaderService.setLoading(false)
        if (data && data.statusCode == 200) {

          this.viewDetails = data.data; // Store or use the data as needed
          if (this.viewDetails?.convertedJson) {
            this.viewDetails.convertedJson = JSON.parse(
              this.viewDetails.convertedJson
            );
          }
          if (this.viewDetails?.rawJson) {
            this.viewDetails.rawJson = JSON.parse(this.viewDetails.rawJson);
            this.viewDetails.date_Time = this.viewDetails.receivedTime;
            this.viewDetails.macAddress = this.viewDetails.deviceAddress;
            this.viewDetails.utilityType = this.viewDetails.utility;
          }
       
        } else {
          this.sharedService.showMessageDialog(
            data.data.message, '',
            SweetAlertIcon.ERROR
          );
        }

      },
      error: (error) => {
        console.error('Error fetching raw data:', error);
      }
    });
  }


  ngOnInit(): void {
    // Example of handling queryParams if necessary
    this.route.queryParams.subscribe((params: any) => {
      if (params.projectDetails) {
        // Do something with projectDetails, for example:
        const projectDetails = JSON.parse(params.projectDetails);
        console.log(projectDetails);
      }
    });

    // If history.state.projectDetails is used instead of queryParams:
    // this.type = history.state.type;
    // this.viewDetails = history.state.readings;
    // switch (history.state.type) {
    //   case 'Reading Details':
    //     break;
    //   case 'Archived Details':
    //     this.router.navigate(['/dashboard']);
    //     break;
    //   case 'RAW Data Details':
    //     this.router.navigate(['/postpaidConsumerDashboard']);
    //     break;
    //   default:
    //     console.warn('Unknown role, no dashboard to navigate to.');
    //     return false;
    // }

    // if (history.state.type) {
    //   const readingDetails = history.state.readings;
    //   console.log(readingDetails);
    //   // Additional logic to handle project details from state
    // }
  }
}
