import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { deviceData } from './device-data';
import { LoaderService } from '../../../core/services/loader.service';
import { select, Store } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { process, State, toODataString } from '@progress/kendo-data-query';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  ConfirmationModal,
  table_pageSize,
} from '../../../shared/modals/common';
import { Router } from '@angular/router';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import Swal from 'sweetalert2';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Templates } from '../../../shared/constants/templatesNames';
import { RpBulkUpoadComponent } from '../../../shared/components/rp-bulkupload/rp-bulkupload.component';
import { creategatewayResponse, deleteGateWayResponse, getGatewayExportResponse, getGateWayResponse, updateGateWayResponse } from '../../../store/GateWay/gateway-selector';
import { Subscription } from 'rxjs';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import {
  getGatewayExport,deleteGateWay,
  getGatewayList,
  SetGatewayStatus, resetGateWaysState,
} from '../../../store/GateWay/gateway.action';
import { RpCreateGatewayComponent } from '../rp-create-gateway/rp-create-gateway.component';
@Component({
  selector: 'app-iot-gateways',
  templateUrl: './iot-gateways.component.html',
  styleUrl: './iot-gateways.component.scss',
})
export class IotGatewaysComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  modalRef!: BsModalRef;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public gridData: unknown[] = deviceData;
  public gridView!: unknown[];

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  public sort: SortDescriptor[] = [];
  public pageSize = 10;
  public skip = 0;
  totaldeviceCount: number = 0;
  currentPage: number = 1;
  allDevicesData: any = [];
  gatewayList: any[] = [];
  totalGateWayCount: number = 0;
  public gatewaygridData: GridDataResult = { data: [], total: 0 };
  selectableSettings = this.sharedService.selectableSettings;
  
  gateWayQueryString: string = '';
  
  orderByQueryOfGatewat: string = '';

  public gateWayState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  breadCrumbItems: Array<{}> | undefined;
  gateWaySearch: string = '';
  queryString: string = '';
  orderByQuery: string = '';
  devicesList: any[] = [];
  selectedRowdata: any = [];
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private router: Router,
    private sharedService: SharedService,    
    private PdfexportService: PdfexportService,
  ) {
    this.breadCrumbItems = [{ label: 'GateWays', url: '' }];
    this.getStoreInfo();
  }

  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  private exportSubscription: Subscription = new Subscription();
  getStoreInfo() {
    let getGateway$ = this.store.pipe(select(getGateWayResponse));
    let deletegateway$ = this.store.pipe(select(deleteGateWayResponse));
    let creategatewayResponse$ = this.store.pipe(select(creategatewayResponse));
    let updategateWayResponse$ = this.store.pipe(select(updateGateWayResponse));
    this.exportSubscription = this.store
      .pipe(select(getGatewayExportResponse))
      .subscribe((res: any) => {
        if (res) {
          if (res.items.length > 0) {
            const transformedData = res.items.map((item: any) => ({
              name: item.gatewayProvider.name,
              GatewaySerialNumber: item.serialNumber,
              sttaus: item.isActive,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'gateway');
            this.loaderService.setLoading(false);
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

 


    getGateway$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.gatewayList = [];
        console.log('santosh');
        console.log(res.items);
        
        debugger
        res.items.forEach((item: any) => {
          this.gatewayList.push({
            id: item.id,
            blockName: item.blockName || 'N/A',
            floorNum: item.floorNum || 'N/A',
            gatewayAddress: item.gatewayAddress || 'N/A',
            networkStatus: item.networkStatus || 'N/A',
            projectId: item.projectId || 'N/A',
            projectName: item.projectName || 'N/A',
            shaft: item.shaft || 'N/A',
          });
        });

        //this.gatewayList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalGateWayCount = res.totalCount;
      } else {
        this.gatewayList = [];
      }
      this.loadGatewayTable();
      console.log('gatewayList-->', res, this.gatewayList);
    });

    deletegateway$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.store.dispatch(
          getGatewayList({
            pageIndex: this.currentPage,
            pageSize: this.pageSize,
            searchTerm: this.gateWaySearch,
            filter: this.gateWayQueryString,
            orderBy: this.orderByQueryOfGatewat,
          })
        );
        this.loadGatewayTable();
        console.log('projectsList', res, this.gatewayList);
      }
    });

    creategatewayResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      // alert(this.projectId);
      // console.log(this.projectId);

  
      if (res) {
        this.getAllGatewatList();
      }
      console.log('createGateWay', res);
    });
    updategateWayResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.getAllGatewatList();
      }
      console.log('update Monitoring', res);
    });
  }

  
  getAllGatewatList() {
    this.store.dispatch(
      getGatewayList({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
  }


  private loadGatewayTable(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.gatewayList, state);


    // this.gatewaygridData = {
    //   data: processedData.data,
    //   total: this.totalGateWayCount,
    // };
    this.gatewaygridData = {
      data: processedData.data.map((item: any) => {
        return {
          ...item,
          // Add 'activeStatus' based on 'isActive'
          activeStatus: item.isActive ? 'ON' : 'OFF'
        };
      }),
      total: this.totalGateWayCount,
    };

  }
  

  deleteGateway(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(deleteGateWay({ id: dataItem.id }));

          /* swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          ); */
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            SweetAlertIcon.INFO
          );
        }
      });
  }

  onGatewaySortChange(sort: SortDescriptor[]): void {
    this.gateWayState.sort = sort;
    console.log(this.gateWayState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.gateWayState.sort
    );

    this.orderByQuery = orderByQuery;
    this.getAllGatewatList();
  }


  openBulkUpdate(){
    let pageData = {
      title: Templates.IOT_DEVICE_BULK_UPDATE.id,
    };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });
    // this.modalRef = this.modalService.show(RpUserProfileComponent, { initialState: { profileData }, class: 'modal-lg' });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getAllGatewatList();
        // this.getResidentialUnitlist();
      }
      // Handle the received profile data here
    });
  }

 


  
  public clearSearchValueChangeDevices(ev: string): void {

    if (ev.length == 0) {
      this.gatewayPageChange({ skip:0, take:this.pageSize })
    }
  }


  private loadDevices(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.devicesList, state);
    // this.gatewaygridData = {
    //   data: processedData.data,
    //   total: this.totaldeviceCount,
    // };
    this.gatewaygridData = {
      data: processedData.data.map((device: any) => ({
        ...device, // Spread existing device properties
        ismonitoringmodifiedvalue: device.isMonitoringDevice ? 'Yes' : 'No' // Add new key
      })),
      total: this.totaldeviceCount
    };

  }

  public onGateWayFilterChange(filter: CompositeFilterDescriptor): void {
    this.gateWayState.filter = filter;
    console.log(this.gateWayState);
    const queryString = this.sharedService.createQuery(this.gateWayState);
    this.queryString = this.sharedService.createQuery(this.gateWayState);
    this.getAllGatewatList();
    console.log(queryString);
  }

  protected gatewayPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getAllGatewatList();
  }

  public gatewayOnSelect(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.selectedRowdata.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.selectedRowdata.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isdeviceRowSelected(row.dataItem)) {
        this.selectedRowdata.push(row.dataItem);
      }
    });

    console.log(this.selectedRowdata);
  }

  public isdeviceRowSelected = (rowArg: any) => {
    return this.selectedRowdata.some((row: any) => row.id === rowArg.id);
  };

  // public showTooltip(e: MouseEvent): void {
  //   const element = e.target as HTMLElement;

  //   // Check if the element is a table cell or has the class 'k-column-title'
  //   if (
  //     (element.nodeName === 'TD' && !element.closest('.no-tooltip')) ||
  //     element.classList.contains('k-column-title')
  //   ) {
  //     this.tooltipDir?.toggle(element);
  //   } else {
  //     this.tooltipDir?.hide();
  //   }
  // }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  
  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadDevices();
  }

 

  public ongatewayFilter(value: Event): void {
    // const inputValue = value;
    // this.searchedPayloadConfigValue = value;
    this.gatewayPageChange({ skip:0, take:this.pageSize })
    // this.getDevices();
  }

  public clearSearchValueChangeSupplier(ev: string): void {

    if (ev.length == 0) {
      this.gatewayPageChange({ skip:0, take:this.pageSize })
    }
  }


  // addNewGateWay() {
  //   this.modalRef = this.modalService.show(RpCreateGatewayComponent, {
  //     initialState: {  },
  //     ...this.config,
  //   });
  // }

  addNewGateWay() {
    this.modalRef = this.modalService.show(RpCreateGatewayComponent, {
      initialState: {},
      ...this.config,
      // class: 'modal-lg custom-modal-width', // Add custom class here
    });
  }
  

  ngOnInit(): void {
    // this.gridView = this.gridData;
    this.getAllGatewatList();
  }


  editGateWay(data: any) {
    let gatewayUpdateData = data;
    this.modalRef = this.modalService.show(RpCreateGatewayComponent, {
      initialState: { gatewayUpdateData },
      ...this.config,
    });
  }

  
}
